import apiClient from "./common/apiClient";
import errorHandler from "./common/errorHandler";
import { isInProject } from "~/scripts/utils";

export default {
  async getProjects() {
    try {
      const response = await apiClient.get("/git");
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  async cloneProject(name) {
    return await apiClient.post("/git/clone", {name: name}).catch(function (error) {
      if (error.response) {
        console.log(`ERROR Failed to clone project : ${error.response.status}`);
        console.log(error.response.data);
      }
    });
  },
  async createGitProject(name) {
    return await apiClient.post('/git/create', {name: name}).catch(function (error) {
      if (error.response) {
        console.log(`ERROR Failed to create project : ${error.response.status}`);
        console.log(error.response.data);
      }
    })
  },
  async saveInDb(project, userConnected) {
    let gitProject = await apiClient.post('/git/loadJson', {name: project.name}).catch(function (error) {
      if (error.response) {
        console.log(`ERROR Failed to clone project : ${error.response.status}`);
        console.log(error.response.data);
      }
    });
    if (gitProject.data == null || gitProject.data == "") {
      gitProject = {
        name: project.name,
        gitId: Number(project.id),
        owner: userConnected.name,
        users: [
          {
            name: userConnected.name,
            email: userConnected.email,
            roles: [{roleId: 1}]
          }
        ],
        description: "You still need to import a business case",
        version: 'v0.1'
      }
      return await apiClient.post('/project/saveFromGit', gitProject)
    } else {
      gitProject = gitProject.data
      if (gitProject.owner == userConnected.name || isInProject(gitProject.users, userConnected.name)) {
        gitProject.gitId = Number(gitProject.gitId)
        return await apiClient.post('/project/saveFromGit', gitProject)
      } else {
        await apiClient.post('/project/removeFolder/', {name: gitProject.name})
        return (1)
      }
    }
  },
  async exportProject(project, msg) {
    try {
      const response = await apiClient.post("/git/push", {project: project, msg: msg});
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
};
