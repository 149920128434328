export class Project {
    constructor(obj) {
        this.id = obj.id ? obj.id : null
        this.name = obj.name ? obj.name : "";
        this.description = obj.description ? obj.description : "";
        this.gitId = null;
        this.users = obj.users ? obj.users : [];
        this.version = obj.version ? obj.version : "v.0.1";
        this.owner = obj.owner ? obj.owner : "";
        this.businesscase = obj.businesscase ? obj.businesscase : {}
    }

    //getters
    getId() { return this.id }
    getGitId() { return this.gitId }
    getName() { return this.name }
    getDescription() { return this.desc }
    getUsers() { return this.users }
    getVersion() { return this.version }
    getOwner() { return this.owner }
    getBsCase() { return this.bsCase }

    //setters
    setId(id) { this.id = id }
    setGitId(gitId) { this.gitId = gitId }
    setName(name) { this.name = name }
    setDescription(desc) { this.desc = desc }
    setUsers(users) { this.users = users }
    setVersion(version) { this.version = version }
    setOwner(owner) { this.owner = owner }
    setBsCase(bsCase) { this.bsCase = bsCase }
}